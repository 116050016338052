import { z } from 'zod'
import { baseModelShape } from '../baseModel'

export const workedHoursShape = {
  ...baseModelShape,
  id: z.string().uuid(),
  campaign: z.string().uuid(),
  campaign_name: z.string(),
  fundraiser: z.string().uuid(),
  worked_hours: z.number(),
  success_donation_count: z.number(),
  lead_count: z.number(),
  fundrasier_full_name: z.string(),
  canvassing_org_name: z.string(),
  avg_age_donors: z.number(),
  avg_donation_amount: z.number(),
  count_donor_over_30: z.number(),
  datetime_created: z.string().refine((value) => !isNaN(Date.parse(value)), {
    message: 'Invalid date format',
  }),
}
export const updatedWorkedHourShape = {
  id: z.string(),
  worked_hours: z.number(),
}
export const customRetrieveWorkedHourInputShape = {
  campaign: z.string().uuid(),
  date: z.string().refine((value) => !isNaN(Date.parse(value)), {
    message: 'Invalid date format',
  }),
}
export const customRetrieveAllWorkedHourInputShape = {
  canvassing_org: z.string().uuid(),
  date: z.string().refine((value) => !isNaN(Date.parse(value)), {
    message: 'Invalid date format',
  }),
}

export const rangeRetrieveInputShape = {
  campaign: z.string().uuid(),
  start_date: z.string().refine((value) => !isNaN(Date.parse(value)), {
    message: 'Invalid date format',
  }),
  end_date: z.string().refine((value) => !isNaN(Date.parse(value)), {
    message: 'Invalid date format',
  }),
}

export const rangeRetrieveAllInputShape = {
  canvassing_org: z.string().uuid(),
  start_date: z.string().refine((value) => !isNaN(Date.parse(value)), {
    message: 'Invalid date format',
  }),
  end_date: z.string().refine((value) => !isNaN(Date.parse(value)), {
    message: 'Invalid date format',
  }),
}
